import React from 'react';
import { RelatedBlogsprops } from '../../types/RelatedBlogs';
import { Link, useNavigate } from 'react-router-dom';
import './RecentBlogs.css';

const RecentBlogs: React.FC<RelatedBlogsprops> = ({ blogs }) => {
  const navigate = useNavigate();

  return (
    <div className='related-blogs mb-4'>
      <ul className='list-unstyled'>
        {blogs.map((blog, index) => (
          <li className='d-flex mb-4 gap-2 recent-post-screen' key={index}>
            <img
              src={blog.image}
              alt={blog.title}
              className='me-3 rounded'
              height='100'
              width='100'
              loading='lazy'
            />
            <Link to={`/blog/${blog.slug}`} className='text-decoration-none text-secondary'>
              <h5 className='mb-1 recent-blog-title'>{blog.title}</h5>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RecentBlogs;
