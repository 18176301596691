import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CardProps } from '../../types/BlogCard';
import '../BlogCard/BlogCard.css';

interface BlogRelatedCardsProps {
  relatedBlogs: CardProps[];
}

const BlogRelatedCards: React.FC<BlogRelatedCardsProps> = ({ relatedBlogs }) => {
  const navigate = useNavigate(); 

  const formatDate = (dateString?: string) => {
    if (!dateString) return 'Unknown Date';
    const formattedDate = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    }).format(formattedDate);
  };

  return (
    <div className='container-fluid'>
      <h1 className='py-5'>Related Blogs</h1>
      {relatedBlogs?.length > 0 && (
        <div className='row'>
          {relatedBlogs.map((blog, index) => (
            <div
              key={blog.slug}
              className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex justify-content-center'
            >
              <div className='blog-card'>
                <div className='blog-image'>
                  <img src={blog.image} alt={blog.title} loading='lazy' />
                </div>
                <div className='blog-content'>
                  <span className='fw-500 d-block'>{formatDate(blog.created_at)}</span>
                  <h5 className='blog-title mt-1 text-wrap'>{blog.title}</h5>
                  <div className='blog-tags'>
                    {(typeof blog.tags === 'string'
                      ? blog.tags.split(',')
                      : blog.tags
                    ).map((tag, index) => (
                      <h6 key={index} className='badge me-2'>
                        {tag.trim()}
                      </h6>
                    ))}
                  </div>
                  <div 
                    className='blog-read-more' 
                    onClick={() => navigate(`/blog/${blog.slug}`)} 
                    style={{ cursor: 'pointer' }} 
                  >
                    Read More <span className='arrow'>→</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default BlogRelatedCards;
