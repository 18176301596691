import React from 'react';
import { CardProps } from '../../types/BlogCard';
import './BlogCard.css';

const BlogCard: React.FC<CardProps> = ({
  image,
  title,
  date,
  tags,
  onClick,
}) => {
  const formatDate = (date: string) => {
    const [day, month, year] = date.split('/').map(Number);
    const formattedDate = new Date(year, month - 1, day);
    const options: Intl.DateTimeFormatOptions = {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    };
    return new Intl.DateTimeFormat('en-US', options).format(formattedDate);
  };
  const formatTags = (tags: string | string[]): string[] => {
    if (typeof tags === 'string') {
      return tags.split(',').map((tag) => tag.trim());
    }
    return tags as string[];
  };
  const formattedTags = Array.isArray(tags) ? tags : formatTags(tags);
  return (
    <div className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex justify-content-center'>
      <div className='blog-card'>
        <div className='blog-image'>
          <img src={image} alt={title} loading='lazy' />
        </div>
        <div className='blog-content'>
          <span className='fw-500 d-block'>{formatDate(date)}</span>
          <h5 className='blog-title mt-1 text-wrap'>{title}</h5>
          <div className='blog-tags'>
            {formattedTags.map((tag, index) => (
              <h6 key={index} className='badge bg-primary text-light me-2'>
                {tag}
              </h6>
            ))}
          </div>
          <div className='blog-read-more' onClick={onClick}>
            Read More <span className='arrow'>→</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
