import React from 'react';
import {
  CustomButton,
  DevelopmentServices,
  Faq,
  FeatureSection,
  HeroHeader,
  InfoHeader,
  TestimonialSection,
  UseMetaTags,
  useNavigateToPath,
} from '../../components/AllComponents';
import './Home.css';
import { featureData } from '../../utils/MockData/OurFeatures';
import AchivementsCard from '../../components/AchivementsCard/AchivementsCard';

const Home: React.FC = () => {
  const navigateToContactus = useNavigateToPath('/contact-us') ?? '#';

  UseMetaTags({
    title: 'Mayora Infotech - Leading IT Services & Solutions Provider',
    description:
      'Mayora Infotech provides cutting-edge IT services including web development, software solutions, and digital marketing. Partner with us for innovative technology solutions.',
    keywords:
      'IT services, web development, software solutions, digital marketing, mobile app development, IT consulting, custom software, cloud solutions.',
    author: 'Mayora Infotech',
    language: 'English',
    ogtitle: 'Mayora Infotech - Leading IT Services & Solutions Provider',
    ogdescription:
      'Mayora Infotech specializes in providing IT services such as web development, digital marketing, software solutions, and IT consulting.',
    ogImage: '%PUBLIC_URL%/favicon.ico',
    ogtype: 'website',
    ogurl: 'https://mayorainfotech.com/',
    canonical: 'https://mayorainfotech.com/',
  });

  return (
    <div className='home-page'>
      <section id='heroheader'>
        <HeroHeader />
      </section>
      <section id='developmentservice' className='section-spacing'>
        <InfoHeader
          title='Our Software Development Services'
          description=" Mayora Infotech offers a range of services from IoT-based
            Development, WebRTC-based Development to custom Mobile and Web app
            Development tailored to meet client's needs."
        />
        <DevelopmentServices />
      </section>
      <section id='ourfeatures' className='section-spacing py-5'>
        <InfoHeader
          title='Empowering You with Features Designed for Success'
          description='Mayora Infotech delivers features that combine high performance
              with ease of use, such as integrated workflows, intuitive
              dashboards, and powerful reporting tools, to streamline your
              business processes.'
        />
        <FeatureSection
          title={featureData[0].title}
          content={featureData[0].content}
          features={featureData[0].features}
          imageSrc={featureData[0].imageSrc}
          imageOrder={1}
          contentOrder={2}
        />
        <div className='container'>
          <div className='d-flex justify-content-center'>
            <div className='col-4 col-sm-6 col-md-4 col-lg-3 mb-4'>
              <AchivementsCard
                value={45}
                label='Projects'
                highlightColor='rgba(0, 123, 255, 1)'
              />
            </div>
            <div className='col-4 col-sm-6 col-md-4 col-lg-3 mb-4'>
              <AchivementsCard
                value={25}
                label='Clients'
                highlightColor='rgba(0, 123, 255, 1)'
              />
            </div>
            <div className='col-4 col-sm-6 col-md-4 col-lg-3 mb-4'>
              <AchivementsCard
                value={25}
                label='Strength'
                highlightColor='rgba(0, 123, 255, 1)'
              />
            </div>
          </div>
        </div>
      </section>
      <section id='faq' className='section-spacing nunito'>
        <Faq />
      </section>
     
      <section id='testimonial' className='section-spacing nunito'>
        <TestimonialSection />
      </section>
      <section
        id='home-contactus'
        className='wow fadeInUp'
        data-wow-delay='0.1s'
      >
        <div className='d-block d-md-flex home-contactus-container align-items-center px-5'>
          <div>
            <h2
              className='mb-0 animate__pulse wow contact-us-text'
              data-wow-delay='0.1s'
            >
              Innovation Starts with a Conversation. Let’s Discuss Your Ideas!
            </h2>
          </div>
        </div>
        <div>
          <CustomButton
            className='wow fadeInUp home-contactus-button'
            onClick={navigateToContactus}
            label={'Contact Us'}
          />
        </div>
      </section>
    </div>
  );
};
export default React.memo(Home);
