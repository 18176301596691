import React from 'react';
import { BlogContentProps } from '../../types/BlogContent';
import './BlogContent.css';

const BlogContent: React.FC<BlogContentProps> = ({ description, content }) => {
  return (
    <div className='container col-md-8 col-sm-12 mb-4'>
      <h5
        className='blog--detail-desciption'
        dangerouslySetInnerHTML={{ __html: description }}
      ></h5>
      <div
        className='blog--detail-content'
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
};

export default BlogContent;
