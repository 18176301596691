import React from 'react';
import './BlogTags.css';
import { BlogTagsProps } from '../../types/BlogTags';

const BlogTags: React.FC<BlogTagsProps> = ({ tags }) => {
  const formatTags = (tags: string | string[]): string[] => {
    if (typeof tags === 'string') {
      return tags.split(',').map((tag) => tag.trim());
    }
    return tags as string[];
  };

  const formattedTags = Array.isArray(tags) ? tags : formatTags(tags);

  return (
    <div className='blog-tags d-flex flex-column flex-wrap mb-2'>
      <h3 className='p-3'>Tags</h3>
      <div className='px-3'>
        {formattedTags.map((tag, index) => (
          <span key={index} className={`badge me-2 mb-2 `}>
            {tag}
          </span>
        ))}
      </div>
    </div>
  );
};

export default BlogTags;
