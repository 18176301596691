import React, { useState } from 'react';
import './ProducDetailAccordian.css';
import { ProductDetailAccordianProps } from '../../types/ProductDetailAccordian';

const ProductDetailAccordian: React.FC<ProductDetailAccordianProps> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState<number>(0);

  return (
    <>
      <section className='full-width-section pt-8 mt-4'>
        <div className='container tab-container'>
          <h1
            className='section-header text-primary wow fadeInUp'
            data-wow-delay='0.3s'
          >
            Focus on cutting-edge technologies and cost-quality efficiency
          </h1>
          <div className='row mt-3'>
            <div className='col-lg-4 col-md-12 col-12 mb-4'>
              <div
                className='nav flex-column nav-pills'
                role='tablist'
                aria-orientation='vertical'
              >
                {tabs.map((tab, index) => (
                  <button
                    key={index}
                    className={`nav-tab ${
                      activeTab === index ? 'active' : ''
                    } wow animate__zoomInRight `}
                    data-wow-delay='0.3s'
                    data-wow-duration='2.1s'
                    onClick={() => setActiveTab(index)}
                    role='tab'
                  >
                    <span className='content-fonts'>{tab.name}</span>
                    <p className='tab-description content-fonts'>{tab.description}</p>
                  </button>
                ))}
              </div>
            </div>

            <div className='col-lg-8 col-md-12 col-12 tab-content mt-2 p-0'>
              <div className='tab-laptop-mockup '>
                <div
                  className='tab-image-wrapper wow animate__fadeIn'
                  data-wow-delay='0.1s'
                >
                  {tabs.map((tab, index) => (
                    <img
                      key={index}
                      src={tab.full_image_url}
                      alt={tab.name}
                      className={`tab-image wow animate__zoomInLeft ${
                        activeTab === index ? 'active' : ''
                      } ${
                        index < activeTab
                          ? 'slide-left'
                          : index > activeTab
                          ? 'slide-right'
                          : ''
                      }`}
                      data-wow-delay='0.3s'
                      data-wow-duration='2.1s'
                      loading='lazy'
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductDetailAccordian;
