import React, { useEffect, useState } from 'react';
import './Products.css';
import { products } from '../../utils/MockData/Products';
import {
  Breadcrumb,
  InfoHeader,
  ProductBlock,
  UseMetaTags,
  UseWowEffect,
} from '../../components/AllComponents';
import { TechnologyService } from '../../utils/APIsService/APIsServices';
import { Service } from '../../types/APIs';

const Products = () => {
  UseWowEffect();

  UseMetaTags({
    title: 'Our Products - Mayora Infotech | Innovative IT Solutions',
    description:
      'Explore the range of innovative products offered by Mayora Infotech. From software solutions to IT services, discover how we can help your business thrive.',
    keywords:
      'IT products, software solutions, technology services, Mayora Infotech, Ahmedabad, business solutions',
    author: 'Mayora Infotech',
    language: 'English',
    ogtitle: 'Products - Mayora Infotech',
    ogdescription:
      'Discover our cutting-edge products designed to meet the needs of businesses in Ahmedabad. Learn how Mayora Infotech can empower your operations with technology.',
    ogImage: '%PUBLIC_URL%/favicon.ico',
    ogtype: 'website',
    ogurl: 'https://mayorainfotech.com/products/',
    canonical: 'https://mayorainfotech.com/products/',
  });

  return (
    <>
      <Breadcrumb
        title='Products'
        currentPage='Products'
        currentPath='/products'
      />
      <div className='container-fluid py-5 mt-4'>
        <InfoHeader
          title='Innovative Products Designed to Solve Real Challenges'
          description="At Mayora Infotech, our products are more than just tools-they are
              enablers of innovation. With solutions designed for flexibility,
              security, and scalability, we provide everything you need to
              thrive in today's fast-paced world"
        />
      </div>
      <div className='container'>
        <div className='product-image-link'>
          <ProductBlock products={products}  />
        </div>
      </div>
    </>
  );
};

export default Products;
