import React from 'react';
import {
  BlogCard,
  Breadcrumb,
  DataNotFound,
  DevelopmentProcessCard,
  Spinner,
  TechnologyAboutServicesCard,
  TechnologyDetailsHeader,
  TechnologySkills,
  useFetch,
  UseMetaTags,
} from '../../components/AllComponents';
import { BlogType, TechnologyDetailsApiResponse } from '../../types/APIs';
import {
  BlogsService,
  TechnologyDetailsService,
} from '../../utils/APIsService/APIsServices';
import { useNavigate, useParams } from 'react-router-dom';
import { technologycard } from '../../utils/MockData/TechnologyDetails';
import BlogRelatedCards from '../../components/BlogsReletedCards/BlogsReletedCards';

const TechnologyDetails = () => {
  const { slug } = useParams<{ slug: string }>();
  const {
    data: technology,
    loading: techLoading,
    error: techError,
  } = useFetch<TechnologyDetailsApiResponse>(async () => {
    if (!slug) {
      throw new Error('Slug is missing');
    }
    const response = await TechnologyDetailsService.getTechnologyService(slug);
    return response;
  }, true);

  const technologyData = technology?.data;

  const navigate = useNavigate();

  const handleBlogsClick = (slug: string) => {
    navigate(`/blog/blog-details/${slug}`);
  };

  UseMetaTags({
    title: technology?.data?.name
      ? `${technology.data.name} - Mayora Infotech | Leading IT Solutions in Ahmedabad`
      : 'Technology Details - Mayora Infotech',
    description:
      technology?.data?.description ||
      'Explore the detailed services and technologies offered by Mayora Infotech.',
    keywords:
      technology?.data?.skills?.join(', ') ||
      'IT services, software solutions, technology services, Ahmedabad',
    author: 'Mayora Infotech',
    language: 'English',
    ogtitle: technology?.data?.name
      ? `${technology.data.name} - Mayora Infotech`
      : 'Technology Details - Mayora Infotech',
    ogdescription:
      technology?.data?.description ||
      'Explore the detailed services and technologies offered by Mayora Infotech.',
    ogImage: technology?.data?.image || '%PUBLIC_URL%/favicon.ico',
    ogtype: 'website',
    ogurl: technology?.data?.slug
      ? `https://mayorainfotech.com/service/detail/${technology.data.slug}`
      : 'https://mayorainfotech.com/service/',
    canonical: `https://mayorainfotech.com/service/detail/${
      technology?.data?.slug || ''
    }`,
  });

  return (
    <>
    <div className='container-fluid'>
      <Breadcrumb
        apititle={technology?.data?.name || 'Service Details'}
        prevPage='Services'
        prevPath='/services'
        currentPage={technology?.data?.name ? technology.data.name : undefined}
        currentPath={
          technology?.data?.slug
            ? `/service/detail/${technology.data.slug}`
            : '/'
        }
      />
      {techLoading ? (
        <div className='text-center position-relative h-50'>
          <div className='d-flex align-items-center justify-content-center api-data-height'>
            <Spinner background='transparent' fixed={false} translate={false} />
          </div>
        </div>
      ) : techError || !technologyData ? (
        <div className='col-12 text-center'>
          <DataNotFound />
        </div>
      ) : (
        <>
          <TechnologyDetailsHeader
            title={technology?.data.name}
            description={technology?.data.description}
            imageSrc={technology?.data.image || 'https://as2.ftcdn.net/v2/jpg/12/27/80/85/1000_F_1227808538_W4QhWBDWfzL68tOqJdpj6gPMtNM1ddiT.jpg' }
            avatarSrc=''
            logoSrc={technology?.data.icon}
            name={technology?.data.name}
          />
          <TechnologyAboutServicesCard
            technologycard={technology?.data.driven || technologycard}
            servicecardheading={technology?.data.name}
            servicecardheadingsummary={technology?.data.name}
          />
          <TechnologySkills
            skills={technology?.data.skills}
            skillname={technology?.data.name}
          />
          {/* <DevelopmentProcessCard
            developmentproccesssummary={technology?.data.name}
            developmentprocess={technology?.data.development_processes}
            title={technology?.data.name}
          /> */}
          <div className='container py-5'>
            {technology?.data?.getRelatedBolgs &&
              technology?.data?.getRelatedBolgs.length > 0 && (
                <BlogRelatedCards
                  relatedBlogs={technology?.data?.getRelatedBolgs}
                />
              )}
          </div>
        </>
      )}
      </div>
    </>
  );
};

export default TechnologyDetails;
