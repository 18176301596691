import React, { Suspense, useState } from 'react';
import './ProductBlock.css';
import { DataNotFound, Spinner, useFetch } from '../AllComponents';
import { ProductsApiResponse } from '../../types/APIs';
import { ProductsService } from '../../utils/APIsService/APIsServices';
import { ProductBlockProps } from '../../types/ProductsBlockTypes';
import { useLocation, useNavigate } from 'react-router-dom';

const ProductBlock: React.FC<ProductBlockProps> = ({ onTabClick }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { data, loading, error } = useFetch<ProductsApiResponse>(
    () => ProductsService.getProducts(),
    pathname === '/products'
  );

  const productsData = data?.data || [];
  

  const handleClick = (product: { slug?: string; product_name: string }) => {
    const formattedName = product.product_name.replace(/\s+/g, '-').toLowerCase(); 
    const productSlug = product.slug || formattedName; 
    
    navigate(`${productSlug}`);
  };
  
  return (
    <div className='container-fluid blog py-5 mt-4'>
      <div className='container py-5'>
        <section className='product-block container'>
          <div className='row'>
            <div className='row g-3 mt-5 justify-content-center api-data-height'>
              {loading ? (
                <div className='col-12 text-center'>
                  <Spinner background='transparent' translate={false} fixed={false} />
                </div>
              ) : error ? (
                <div className='col-12 text-center'>
                  <DataNotFound />
                </div>
              ) : (
                productsData.map((product, index) => (
                  <div
                    key={product.slug} 
                    className='col-12 col-sm-10 col-md-6 col-lg-4 mx-auto'
                    onClick={() => handleClick(product)}
                  >
                    <div className={`product-tile wow fadeInUp`} data-wow-delay={`${index * 0.2}s`}>
                      <div className='product-details'>
                        <h2 className='text-secondary'>{product.product_name}</h2>
                        <p className='nunito'>{product.product_description}</p>
                      </div>
                      <Suspense fallback={<Spinner fixed={false} />}>
                        <div className='image-wrapper'>
                          <img
                            src={product.product_header_image}
                            alt={product.product_name}
                            loading='lazy'
                            className='product-image'
                            height='200'
                          />
                        </div>
                      </Suspense>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ProductBlock;
