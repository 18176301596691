import React, { FC } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { Spinner, TestimonialCard, useFetch } from '../AllComponents';
import { TestimonialService } from '../../utils/APIsService/APIsServices';
import defaultImage from '../../assets/img/detault2.png';
import { Testimonialsettings } from '../../utils/SliderSettings';


interface SwiperComponentProps {
  testimonials: {
    image?: string | null;
    name: string;
    descraption: string;
  }[];
}

const SwiperComponent: FC<SwiperComponentProps> = ({ testimonials }) => {

  return (
    <div className='slider-container'>
      {testimonials.length > 0 ? (
        <Slider {...Testimonialsettings}>
          {testimonials.map((testimonial, index) => (
            <div key={index} className='testimonial-slider'>
              <TestimonialCard
                imgSrc={testimonial.image || defaultImage}
                name={testimonial.name}
                description={testimonial.descraption}
              />
            </div>
          ))}
        </Slider>
      ) : (
        <p>No testimonials available.</p>
      )}
    </div>
  );
};

export default SwiperComponent;
