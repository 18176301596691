import React, { FC } from 'react';
import './TechnologyDetailsHeader.css';
import { TechnologyDetailsHeaderProps } from '../../types/TechnologyDetailsHeader';

import * as AiIcons from 'react-icons/ai';
import * as BiIcons from 'react-icons/bi';
import * as DiIcons from 'react-icons/di';
import * as FiIcons from 'react-icons/fi';
import * as FaIcons from 'react-icons/fa';
import * as GiIcons from 'react-icons/gi';
import * as MdIcons from 'react-icons/md';
import * as HiIcons from 'react-icons/hi';
import * as IoIcons from 'react-icons/io5';
import * as GoIcons from 'react-icons/go';
import * as RiIcons from 'react-icons/ri';
import * as SiIcons from 'react-icons/si';
import * as TbIcons from 'react-icons/tb';
import * as TiIcons from 'react-icons/ti';
import * as VscIcons from 'react-icons/vsc';
import * as WiIcons from 'react-icons/wi';

const getReactIcon = (iconName: string) =>
  (AiIcons as Record<string, React.ElementType>)[iconName] ||
  (BiIcons as Record<string, React.ElementType>)[iconName] ||
  (DiIcons as Record<string, React.ElementType>)[iconName] ||
  (FiIcons as Record<string, React.ElementType>)[iconName] ||
  (FaIcons as Record<string, React.ElementType>)[iconName] ||
  (GiIcons as Record<string, React.ElementType>)[iconName] ||
  (MdIcons as Record<string, React.ElementType>)[iconName] ||
  (HiIcons as Record<string, React.ElementType>)[iconName] ||
  (IoIcons as Record<string, React.ElementType>)[iconName] ||
  (GoIcons as Record<string, React.ElementType>)[iconName] ||
  (RiIcons as Record<string, React.ElementType>)[iconName] ||
  (SiIcons as Record<string, React.ElementType>)[iconName] ||
  (TbIcons as Record<string, React.ElementType>)[iconName] ||
  (TiIcons as Record<string, React.ElementType>)[iconName] ||
  (VscIcons as Record<string, React.ElementType>)[iconName] ||
  (WiIcons as Record<string, React.ElementType>)[iconName];

const TechnologyDetailsHeader: FC<TechnologyDetailsHeaderProps> = ({
  title,
  description,
  imageSrc,
  logoSrc,
  name
}) => {
  const cardClassName = name
    ? name.toLowerCase().replace(/\s+/g, '-').replace(/\./g, 'dot')
    : '';

  const IconComponent = logoSrc ? getReactIcon(logoSrc) : null;
  const getValidFirstLetter = (text: string) => {
    if (!text) return '?';
    const validLetter = text.split('').find((char) => /[a-zA-Z0-9]/.test(char));
    return validLetter ? validLetter.toUpperCase() : '?';
  };
  const fallbackLetter = getValidFirstLetter(name || '');

  return (
    <section className='wow fadeIn overflow-hidden mt-5' style={{ visibility: 'visible', animation: 'fadeIn;' }}>
      <div className='container-xl'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-6 order-lg-1 pe-lg-5'>
              <div className='position-relative technology-top-image'>
                <img src={imageSrc} alt={title} loading='lazy' className='img-fluid vector-image' />
                
                <div className={`logo logo-background ${cardClassName}`}>
                  {IconComponent ? (
                    <IconComponent className='icon-class' size={50} />
                  ) : (
                    <span className='fallback-icon'>{fallbackLetter}</span>
                  )}
                </div>
              </div>
            </div>
            <div
              className='col-lg-6 order-lg-2'
              style={{
                height: '500px',
                overflowY: 'auto',
                padding: '10px',
              }}
            >
              <h1 className='highlight'>{title}</h1>
              <span dangerouslySetInnerHTML={{ __html: description || '' }} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TechnologyDetailsHeader;
