import React from 'react';
import './TechnologySkills.css';
import pointer from '../../assets/img/pointer.svg';
import { TechnologySkillsprops } from '../../types/echnologySkills';

const TechnologySkills: React.FC<TechnologySkillsprops> = ({
  skills,
  skillname
}) => {
  return (
    <div className='container-fluid service py-4'>
      <div className='container text-center pt-3'>
        <h2 className='highlight'>
          {skillname} <span className='skills-title'>Skills</span>
        </h2>
        <h5 className='company-info nunito font-sm-text'>
          At Mayora Infotech, we specialize in delivering cutting-edge
          technology solutions that drive innovation and empower businesses to
          achieve their goals. With a team of skilled professionals and a
          commitment to excellence, we provide robust development, cloud
          infrastructure, and strategic consulting to help our clients succeed
          in a rapidly evolving digital landscape.
        </h5>
      </div>
      <div className='skills-list container pt-3'>
        {skills.map((skill, index) => (
          <div key={index} className='col-12 col-md-6 col-lg-3 skill-item'>
            <h3 className='skill-title'>{skill.name}</h3>
            <span className='separator'></span>
            <span className='skill-description content-fonts'>
              <img
                src={pointer}
                loading='lazy'
                className='img-fluid position-relative roundpointer'
                alt='icon'
              />
              {skill.descraption}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TechnologySkills;
