import React from 'react';
import { ProductsDetailsFeatureProps } from '../../types/ProductsDetailsFeature';
import './ProductsDetailsFeature.css';
import * as AiIcons from 'react-icons/ai';
import * as BiIcons from 'react-icons/bi';
import * as DiIcons from 'react-icons/di';
import * as FiIcons from 'react-icons/fi';
import * as FaIcons from 'react-icons/fa';
import * as GiIcons from 'react-icons/gi';
import * as MdIcons from 'react-icons/md';
import * as HiIcons from 'react-icons/hi';
import * as IoIcons from 'react-icons/io5';
import * as GoIcons from 'react-icons/go';
import * as RiIcons from 'react-icons/ri';
import * as SiIcons from 'react-icons/si';
import * as TbIcons from 'react-icons/tb';
import * as TiIcons from 'react-icons/ti';
import * as VscIcons from 'react-icons/vsc';
import * as WiIcons from 'react-icons/wi';
import * as CiIcons from 'react-icons/ci';

const getIconComponent = (icon: string): JSX.Element | null => {
  if (!icon) return null;

  if (icon.startsWith('bi ')) {
    return <i className={icon} style={{ fontSize: '2rem' }}></i>;
  }

  const ReactIcon =
    (AiIcons as Record<string, React.ElementType>)[icon] ||
    (BiIcons as Record<string, React.ElementType>)[icon] ||
    (DiIcons as Record<string, React.ElementType>)[icon] ||
    (FiIcons as Record<string, React.ElementType>)[icon] ||
    (FaIcons as Record<string, React.ElementType>)[icon] ||
    (GiIcons as Record<string, React.ElementType>)[icon] ||
    (MdIcons as Record<string, React.ElementType>)[icon] ||
    (HiIcons as Record<string, React.ElementType>)[icon] ||
    (IoIcons as Record<string, React.ElementType>)[icon] ||
    (GoIcons as Record<string, React.ElementType>)[icon] ||
    (RiIcons as Record<string, React.ElementType>)[icon] ||
    (SiIcons as Record<string, React.ElementType>)[icon] ||
    (TbIcons as Record<string, React.ElementType>)[icon] ||
    (TiIcons as Record<string, React.ElementType>)[icon] ||
    (VscIcons as Record<string, React.ElementType>)[icon] ||
    (WiIcons as Record<string, React.ElementType>)[icon] ||
    (CiIcons as Record<string, React.ElementType>)[icon];

  if (ReactIcon) {
    return <ReactIcon className='feature-icon' />;
  }

  return <FaIcons.FaRegStar className='feature-icon' />;
};

const ProductsDetailsFeature: React.FC<ProductsDetailsFeatureProps> = ({
  backgroundGradient,
  features,
  imageSrc,
  name,
}) => {
  return (
    <div
      id='features'
      className='features-section section overflow-visible mt-5 pr-lg-5'
      style={{ background: backgroundGradient }}
    >
      <div className='container-fluid'>
        <div className='features-content'>
          <div className='d-flex justify-content-end flex-wrap'>
            <div className='col-lg-7 order-lg-2 mb-lg-4'>
              <div className='section-head text-lg-start'>
                <h2 className='heading'>Amazing Features</h2>
                <span className='fw-bold'>
                  {name} comes packed with advanced features designed for
                  maximum efficiency and convenience. Crafted with high-quality
                  materials, it ensures durability and long-lasting performance.
                  Its user-friendly design makes it easy to operate, while
                  cutting-edge technology enhances its functionality. The sleek
                  and modern look adds a touch of sophistication, making it a
                  perfect fit for any setting. Whether for personal or
                  professional use, {name} delivers reliable and consistent
                  results, making it a versatile choice for all your needs.
                </span>
              </div>
              <div className='row'>
                {features.map((feature, index) => (
                  <div key={index} className={`col-md-6 content-padding`}>
                    <div className='single-feature content-box-size'>
                      {getIconComponent(feature.icon)}
                      <h4>{feature.name}</h4>
                      <p className='font-size-box'>{feature.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className='col-lg-5 pt-100 text-center'>
              <div
                className='fearures-software-mockup wow fadeInLeft'
                data-wow-duration='.5s'
              >
                <img
                  src={imageSrc}
                  alt={`${name}-feature`}
                  className='img-fluid'
                  loading='lazy'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsDetailsFeature;
