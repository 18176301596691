import React from 'react';
import './TechCard.css';
import { TechCardProps } from '../../types/TechCardTypes';
import { useNavigate } from 'react-router-dom';
import * as AiIcons from 'react-icons/ai';
import * as BiIcons from 'react-icons/bi';
import * as DiIcons from 'react-icons/di';
import * as FiIcons from 'react-icons/fi';
import * as FaIcons from 'react-icons/fa';
import * as GiIcons from 'react-icons/gi';
import * as MdIcons from 'react-icons/md';
import * as HiIcons from 'react-icons/hi';
import * as IoIcons from 'react-icons/io5';
import * as GoIcons from 'react-icons/go';
import * as RiIcons from 'react-icons/ri';
import * as SiIcons from 'react-icons/si';
import * as TbIcons from 'react-icons/tb';
import * as TiIcons from 'react-icons/ti';
import * as VscIcons from 'react-icons/vsc';
import * as WiIcons from 'react-icons/wi';

const TechCard: React.FC<TechCardProps> = ({ icon, name, slug }) => {
  const navigate = useNavigate();

  const cardClassName = name
    .toLowerCase()
    .replace(/\s+/g, ' ')
    .replace('.', 'dot');

  const handleCardClick = () => {
    navigate(`${slug}`);
  };
  const getValidFirstLetter = (text: string) => {
    if (!text) return '?';
    const validLetter = text.split('').find((char) => /[a-zA-Z0-9]/.test(char));
    return validLetter ? validLetter.toUpperCase() : '?';
  };
  
  const fallbackLetter = getValidFirstLetter(name);

  const isHTMLString = (icon: any) =>
    typeof icon === 'string' && icon.trim().startsWith('<i');

  const isReactComponent = (icon: any) => React.isValidElement(icon);

  const getReactIcon = (iconName: string) =>
    (AiIcons as Record<string, React.ElementType>)[iconName] ||
    (BiIcons as Record<string, React.ElementType>)[iconName] ||
    (DiIcons as Record<string, React.ElementType>)[iconName] ||
    (FiIcons as Record<string, React.ElementType>)[iconName] ||
    (FaIcons as Record<string, React.ElementType>)[iconName] ||
    (GiIcons as Record<string, React.ElementType>)[iconName] ||
    (MdIcons as Record<string, React.ElementType>)[iconName] ||
    (HiIcons as Record<string, React.ElementType>)[iconName] ||
    (IoIcons as Record<string, React.ElementType>)[iconName] ||
    (GoIcons as Record<string, React.ElementType>)[iconName] ||
    (RiIcons as Record<string, React.ElementType>)[iconName] ||
    (SiIcons as Record<string, React.ElementType>)[iconName] ||
    (TbIcons as Record<string, React.ElementType>)[iconName] ||
    (TiIcons as Record<string, React.ElementType>)[iconName] ||
    (VscIcons as Record<string, React.ElementType>)[iconName] ||
    (WiIcons as Record<string, React.ElementType>)[iconName];

  let renderedIcon = null;

  if (isHTMLString(icon)) {
    renderedIcon = <span dangerouslySetInnerHTML={{ __html: icon }} />;
  } else if (isReactComponent(icon)) {
    renderedIcon = icon;
  } else if (typeof icon === 'string') {
    const IconComponent = getReactIcon(icon);
    renderedIcon = IconComponent ? <IconComponent /> : null;
  }

  return (
    <div
      className={`tech-card wow fadeInUp ${cardClassName}`}
      data-wow-delay='0.1s'
      onClick={handleCardClick}
    >
      <div className='icon-container'>
        <div className='circle'></div>
        {renderedIcon ? (
          <span className='tech-icon'>{renderedIcon}</span>
        ) : (
          <span className='tech-icon'>{fallbackLetter}</span>
        )}
      </div>
      <h3 className='content'>{name}</h3>
    </div>
  );
};

export default TechCard;
