import React, { useEffect, useState } from 'react';
import {
  CustomButton,
  DataNotFound,
  InfoHeader,
  ProductCarousel,
  ProductDetailAccordian,
  ProductsDetailsFaq,
  ProductsDetailsFeature,
  ProductsDetailsHeader,
  Spinner,
  SwiperComponent,
  TestimonialCard,
  useFetch,
  UseMetaTags,
  useNavigateToPath,
  VideoSection,
} from '../../components/AllComponents';
import './productsdetails.css';
import moniterdeshboard from '../../assets/img/moniter deshboard.png';
import {
  getGradientById,
  getGradientFeaturesBackgroundById,
  getGradientTextById,
  getGradientTitleTextById,
  setBackgroundFaqGradientById,
} from '../../utils/GradientColors';
import { useParams } from 'react-router-dom';
import { ProductsDetailsApiResponse } from '../../types/APIs';
import { ProductsDetailsService } from '../../utils/APIsService/APIsServices';
import { NavLink } from 'react-router-dom';
import { tabs } from '../../utils/MockData/ProductDetailAccordian';
import Slider from 'react-slick';
import { Testimonialsettings } from '../../utils/SliderSettings';
import { ProductsfaqData } from '../../utils/MockData/ProductsDetailsFaq';
import { testimonials } from '../../utils/MockData/TestimonialSection';
import {
  aboutContent,
  featuresData,
} from '../../utils/MockData/ProductsDetails';

const ProductsDetails: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const { data, error, loading } = useFetch<ProductsDetailsApiResponse>(
    () => ProductsDetailsService.getProductDetails(slug ?? ''),
    !!slug
  );

  UseMetaTags({
    title: `${
      data?.data?.product_name || 'Product'
    } - Mayora Infotech | Innovative IT Solutions`,
    description:
      data?.data?.product_header_description ||
      'Discover innovative IT solutions.',
    keywords: `${
      data?.data?.product_name || 'Product'
    }, IT solutions, technology services, Mayora Infotech`,
    author: 'Mayora Infotech',
    language: 'English',
    ogtitle: data?.data?.product_name || 'Product - Mayora Infotech',
    ogdescription:
      data?.data?.product_header_description ||
      'Explore the product details and its features.',
    ogImage:
      data?.data?.product_header_image ||
      '%PUBLIC_URL%/default-product-image.jpg',
    ogtype: 'product',
    ogurl: `https://mayorainfotech.com/products/${
      data?.data?.product_name || 'default-product'
    }`,
    canonical: `https://mayorainfotech.com/products/${
      data?.data?.product_name || 'default-product'
    }`,
  });


  const [productId, setProductId] = useState<number | null>(null);
  const navigateToContactus = useNavigateToPath('/contact-us') ?? '#';
  const [backgroundGradient, setBackgroundGradient] = useState<string>('');
  console.log('backgroundGradient====', backgroundGradient);
  const [backgroundTextGradient, setBackgroundTextGradient] =
    useState<string>('');
  const [backgroundTitleextGradient, setBackgroundTitleTextGradient] =
    useState<string>('');
  const [backgroundFaqGradient, setBackgroundFaqGradient] =
    useState<string>('');
  console.log('backgroundFaqGradient====', backgroundFaqGradient);

  const [
    backgroundProductsDetailsFeturesGradient,
    setBackgroundProductsDetailsFeturesGradient,
  ] = useState<string>('');

  useEffect(() => {
    if (data?.data?.id) {
      setProductId(data.data.id);
    }
  }, [data]);

  useEffect(() => {
    if (productId !== null) {
      const gradient = getGradientById(productId);
      setBackgroundGradient(gradient);
      const text = getGradientTextById(productId);
      setBackgroundTextGradient(text);
      const titletext = getGradientTitleTextById(productId);
      setBackgroundTitleTextGradient(titletext);
      const faqbackground = setBackgroundFaqGradientById(productId);
      setBackgroundFaqGradient(faqbackground);
      const ProductsDetailsFeturesbackground =
        getGradientFeaturesBackgroundById(productId);
      setBackgroundProductsDetailsFeturesGradient(
        ProductsDetailsFeturesbackground
      );
    }
  }, [productId]);

  const images = data?.data?.multiple_product_images || [moniterdeshboard];

  if (loading) {
    return (
      <div className="text-center position-relative h-50">
        <div className="d-flex align-items-center justify-content-center api-data-height">
          <Spinner background="transparent" fixed={false} translate={false} />
        </div>
      </div>
    );
  }

  if (error || !data) {
    return (
      <div className="col-12 text-center">
        <DataNotFound />
      </div>
    );
  }

  return (
    // {loading ? (
    //   <div className='text-center position-relative h-50'>
    //     <div className='d-flex align-items-center justify-content-center api-data-height'>
    //       <Spinner background='transparent' fixed={false} translate={false} />
    //     </div>
    //   </div>
    // ) : techError || !technologyData ? (
    //   <div className='col-12 text-center'>
    //     <DataNotFound />
    //   </div>
    // ) : (
    <>
      <ProductsDetailsHeader
        backgroundGradient={backgroundGradient}
        imgSrc={data?.data.product_header_image || moniterdeshboard}
        imgAlt={data?.data.product_name || 'Monitor Dashboard'}
        titleText={data?.data.product_name || 'Monitor Dashboard'}
        titleStyle={{
          color: backgroundTextGradient,
          fontWeight: 'bold',
          fontSize: '50px',
        }}
        typewriterWords={['Innovation', 'Performance', 'Reliability']}
        textColor={backgroundTitleextGradient}
        navigateTo='#'
        productsdescription={
          data?.data?.about_product ||
          `MonitorMate is an all-in-one employee activity management solution that combines 
          real-time screen monitoring, productivity tracking, attendance, leave management, 
          and performance analytics to help organizations optimize employee engagement, 
          ensure accountability, and streamline HR functions.`
        }
      />
      <div
        id='products-about'
        className='products-about-section section pb-5 bg-white'
      >
        <div className='container'>
          <div className='section-head text-center mb-5'>
            <div className='row'>
              <div className='col-md-12'>
                <h2 className='products-about-heading'>
                  About{' '}
                  <span className='appsland'>{data?.data.product_name}</span>
                </h2>
                <p className='content-fonts'>
                  {data?.data.product_name} is a high-quality, reliable, and
                  efficient solution designed to meet your needs. Built with
                  premium materials and advanced technology, it ensures
                  durability and top-notch performance. Whether for personal or
                  professional use, {data?.data.product_name} delivers
                  exceptional results with ease. Its user-friendly design makes
                  it simple to use, while its sleek and modern appearance adds a
                  touch of sophistication. Experience the perfect blend of
                  innovation and functionality with {data?.data.product_name},
                  your ideal choice for superior performance.
                </p>
              </div>
            </div>
          </div>
          <VideoSection
            videoSrc={data?.data.about_product_video || aboutContent.videoSrc}
            title={aboutContent.title}
            description={data?.data.about_product || aboutContent.description}
            // listItems={aboutContent.listItems}
          />
        </div>
      </div>
      <ProductCarousel
        productscarousalheading='Explore a wide range of products tailored to meet diverse
            requirements with cutting-edge technology.'
        images={images}
      />
      <ProductsDetailsFeature
        backgroundGradient={backgroundProductsDetailsFeturesGradient}
        features={data?.data.features || featuresData}
        imageSrc={
          data?.data.feature_main_image ||
          'https://demo.themenio.com/appsland/images/software-screen-b.jpg'
        }
        name={data?.data.product_name}
      />
      <ProductDetailAccordian tabs={data?.data.technologies || tabs} />
      <ProductsDetailsFaq
        backgroundGradient={backgroundFaqGradient}
        heading={data?.data.product_name}
        subheading=''
        paragraph={
          <>
            We have compiled a list of FAQs for you. If your question is not
            here, please{' '}
            <NavLink className='text-secondary' to='/contact-us'>
              reach out
            </NavLink>{' '}
            to us.
          </>
        }
        items={data?.data.product_faq || ProductsfaqData}
      />
      <div className='container-fluid testimonial py-5'>
        <div
          className='container py-5 wow animate__zoomInRight'
          data-wow-delay='0.1s'
        >
          <div className='text-center mx-auto mb-5 section-heading'>
            <h4>Testimonial</h4>
            <InfoHeader
              title='Hear from Our Valued Clients'
              description="Our clients share their experiences and the positive impact Mayora's services have had on their businesses. From global brands to innovative startups, their stories highlight our commitment to excellence and innovation."
            />
          </div>
            <SwiperComponent testimonials={data?.data.testimonial || []} />
        </div>
      </div>
      <section
        id='home-contactus'
        className='wow fadeInUp'
        data-wow-delay='0.1s'
      >
        <div className='d-block d-md-flex home-contactus-container align-items-center px-5'>
          <div>
            <h2
              className='mb-0 animate__pulse wow contact-us-text'
              data-wow-delay='0.1s'
            >
              Innovation Starts with a Conversation. Let’s Discuss Your Ideas!
            </h2>
          </div>
        </div>
        <div>
          <CustomButton
            className='wow fadeInUp home-contactus-button'
            onClick={navigateToContactus}
            label='Contact Us'
          />
        </div>
      </section>
    </>
  );
};

export default ProductsDetails;
