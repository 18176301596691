import React, { useRef, useState, useEffect } from 'react';
import './VideoSection.css';
import { VideoSectionprops } from '../../types/VideoSection';

const VideoSection: React.FC<VideoSectionprops> = ({
  videoSrc,
  title,
  description,
  listItems,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const progressRef = useRef<HTMLInputElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.addEventListener('loadedmetadata', () => setDuration(video.duration));
      video.addEventListener('timeupdate', () => setCurrentTime(video.currentTime));
      video.addEventListener('ended', () => setIsPlaying(false));
    }
  }, []);

  const handlePlayPause = () => {
    const video = videoRef.current;
    if (video) {
      isPlaying ? video.pause() : video.play();
      setIsPlaying(!isPlaying);
    }
  };

  const handleProgressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const video = videoRef.current;
    if (video) {
      const newTime = parseFloat(event.target.value);
      video.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <div className='container'>
      <div className='row video-section align-items-center'>
        <div className='col-12 col-md-12 col-lg-6 mb-4 mb-md-0'>
          <div className='video-container position-relative'>
            <video ref={videoRef} src={videoSrc} className='img-fluid' muted />
            {!isPlaying && (
              <button className='play-button' onClick={handlePlayPause}>
                ▶ 
              </button>
            )}
          </div>
          <div className='video-controls'>
            <button className='control-button' onClick={handlePlayPause}>
              {isPlaying ? '❚❚' : '▶'}
            </button>
            <span className='time'>{formatTime(currentTime)}</span>
            <div className='progress-container'>
              <div className='progress-bar' style={{ width: `${(currentTime / duration) * 100}%` }}></div>
              <input
                // ref={progressRef}
                type='range'
                min='0'
                max={duration}
                value={currentTime}
                onChange={handleProgressChange}
              />
            </div>
            <span className='time'>{formatTime(duration)}</span>
          </div>
        </div>
        <div className='col-md-12 col-lg-6 mb-12'>
          <div className='txt-entry'>
            <h3>{title}</h3>
            <h4>{description}</h4>
            {listItems && (
              <ul>
                {listItems.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoSection;
